import {app} from "../../app";
import {AbstractComponent} from "../AbstractComponent/AbstractComponent";
import {ILocationService, ILogService} from "angular";
import {IAppModel, IRootScope} from "../../hpc";
import * as restangular from "restangular";

export class AnnouncementList extends AbstractComponent {

    public static $inject = ['$log', '$scope', '$rootScope', '$sessionStorage','properties' , 'Restangular', '$location'];

    constructor(private $log: ILogService,
                private $s: any,
                private $rs: IRootScope,
                private appModel: IAppModel,
                private properties,
                private Rest: restangular.IService,
                private $location: ILocationService) {
        super();
    }

    public storedAnnouncements;
    public currentType: 'defaultAnnouncement' | 'oooAnnouncement';
    public currentTypeId: number;
    public showTemplates;
    public showAnnouncement;
    public error;
    private accountProperties:any;
    private calendarSyncing = false;
    private enablingSync = false;

    $onInit(): void {
        this.accountProperties = this.Rest.one('accounts/me/accountProperties');
        this.getStoredAnnouncements();

        if(this.$location.search().errormessage){
            console.log('autoOn - error: ' + this.$location.search().errormessage);
            this.error = this.$location.search().errormessage;
            //this.$location.search(null);
        } else if(this.$location.search().autoOn){
            this.enableAutoMode();
            return;
        }

        this.getAccountProperties();

        // if(this.isAutoModeEnabled() && this.isAutoModeConfigured()){
        //     this.syncCalendar();
        // }

    };

    private getStoredAnnouncements(){
        this.storedAnnouncements = this.appModel.storedAnnouncements = this.appModel.storedAnnouncements || {defaultAnnouncement: undefined, oooAnnouncement: undefined};
        this.Rest.one('/accounts/me/defaultAnnouncement').get()
        .then((data) => {
            this.storedAnnouncements.defaultAnnouncement = data;
        });

        this.Rest.one('/accounts/me/oooAnnouncement').get()
        .then((data) => {
            this.storedAnnouncements.oooAnnouncement = data;
        })
    }



    enableAutoMode() {
        this.enablingSync = true;
        console.log('autoOn - switching on calendarsync');
        this.accountProperties.calendarsync = true;
        this.accountProperties.put().then((data) => {
            this.appModel.account.accountProperties = data;
            this.syncCalendar(true);
            this.enablingSync = false;
            this.$location.search({autoOn: null});
        });
    }


    private getAccountProperties() {
        this.accountProperties.get().then((data) => {
            this.appModel.account.accountProperties = data;
        });
    }

    showAnnouncementList = () => {
        return this.appModel.account.accountProperties.calendarsync && !this.$location.search().selectTemplate;
    }

    toggleAutoMode = ($event) => {
        console.log('toggle automode');
        $event.preventDefault();
        this.enablingSync = true;
        //retrieve value of target and authorize if true. disable calendarsync if false
        let target = $event.target;
        if(target.checked){
            this.authorize();
        } else {
            this.accountProperties.calendarsync = false;
            this.accountProperties.put().then((data) => {
                this.appModel.account.accountProperties = data;
                this.enablingSync = false;
                this.$rs.$broadcast('calendarRefresh');
                this.$location.search({autoOn: null});
            });
        }
    }


    authorize() {
        console.log('authorizeing azure');
        let url = this.properties.oauthBaseUrl + 'azure' +
            "?mandatorName=" + this.appModel.mandatorName +
            "&syncMailboxSettings" +
            "&redirect_uri=" + encodeURIComponent(this.$location.absUrl().split('?')[0] + '?autoOn') +
            "&register_uri=" + encodeURIComponent(this.$location.absUrl().split('?')[0].replace('/login', '/register')) +
            "&access_token=" + this.appModel.userToken;

        window.location.href = url;
    }

    syncCalendar = (forceUpdate:boolean=false)=>{
        this.calendarSyncing = true;
        this.Rest.one('accounts/me/syncCalendar').get({forceUpdate: forceUpdate}).then(()=>{
                console.log('calendar synced');
                this.calendarSyncing = false;
                this.$rs.$broadcast('calendarRefresh');
            }
        ).catch((err)=>{
            console.error('error syncing calendar: ' + err);
            this.$rs.errormessage = 'ERROR_SYNCING_OOO';
            this.calendarSyncing = false;
        })
    }

    isAutoModeEnabled = () => {
        return this.appModel.account.accountProperties.calendarsync;
    }

    isAutoModeConfigured = () => {
        return this.appModel.storedAnnouncements.defaultAnnouncement && this.appModel.storedAnnouncements.oooAnnouncement;
    }


    editAnnouncement(type) {
        let announcement = this.storedAnnouncements[type];
        this.currentType = type;
        this.Rest.one('templates', announcement.templateId).get()
            .then(template => {
                announcement.template = template;
                this.appModel.currentAnnouncement = announcement;
                this.appModel.currentTemplate = template;
                this.currentTypeId = template.templateTypeId;
                this.showAnnouncement = true;
                this.$location.path("/greetingDetail").search({type: type, noScheduler: true, isEdit: true, autoOn: true});
            })
    }

    createAnnouncement(createType) {
        this.$location.search({autoOn: true});
    }


}

app.component('announcementList', {
    bindings: {
        onEditAnnouncement: '&',
        onCreateAnnouncement: '&'
    },
    templateUrl: 'ts/components/announcementList/announcementList.html',
    controller: AnnouncementList
})