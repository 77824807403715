import {IAppModel, IProperties, IRootScope} from '../hpc';
import {ILocationService} from 'angular';


export class InfoService {

    private isIOSDevice() {
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
        return /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;
    }

    public static $inject = ['$rootScope','$localStorage','$sessionStorage', 'properties', '$location'];

    constructor(
        private $rs: IRootScope,
        private $localStorage: any,
        private appModel: IAppModel,
        private properties: IProperties,
        private $location: ILocationService,
    ) {

    }

    //check if we are on a ios device
    public displayInitMessages(){
        console.log('displayInitMessages');
        console.log("UserAgent:  " + navigator.userAgent);
        if (this.isIOSDevice() && !this.isConfirmed('livevoicemail') && this.$rs.viewMode == 'mobile' && !this.isSuppressed('livevoicemail')) {
            this.$rs.errormessage = {message: "MESSAGE_LIVE_VOICEMAIL", type: 'warning', id: 'livevoicemail'};
        }
    }

    public confirmErrorMessage = function (id) {
        if(!id){
            return;
        }
        this.$rs.errormessage = null;
        if (this.$localStorage.messageConfirmations === undefined) {
            this.$localStorage.messageConfirmations = {};
        }
        this.$localStorage.messageConfirmations[id] = true;
    }

    public isConfirmed = function (id) {
          return id == null || (this.$localStorage.messageConfirmations !== undefined && this.$localStorage.messageConfirmations[id] === true);
    }

    public isSuppressed = function (id) {
        return this.appModel.mandator.mandatorAttributes.suppressMessages && this.appModel.mandator.mandatorAttributes.suppressMessages.indexOf(id) > -1;
    }



}