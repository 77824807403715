import {IAppModel, ICalendar, IDeliveryStatus, IDeliveryType, ILastDelivery, IRootScope, ITemplateDeliveryTypes} from "../hpc";
import {ILogService} from "angular";
import * as restangular from "restangular";
import * as _ from "underscore";
import {TrackerService} from "./TrackerService";

class Calendar implements ICalendar {
	id: number;
	version: number;
	errorMessage: string;
	begin: number;
	end: number;
	lastChanged: number;
	deliveryTypes: IDeliveryType[];
	deliveryStatus: IDeliveryStatus;
}

export class DeliveryService {

	public static $inject = ['$rootScope','$moment', '$localStorage', '$sessionStorage', '$log', 'tracker', 'Restangular'];

	announcements = this.Rest.all('announcements');

	constructor(
		private $rs: IRootScope,
		private $moment,
		private $localStorage: any,
		private appModel: IAppModel,
		private $log: ILogService,
		private tracker: TrackerService,
		private Rest: restangular.IService,
	) {
	};

	public async activateAnnouncement(announcement, begin, end, deliveryTypes): Promise<void> {
		this.$rs.errormessage = "";
		this.$rs.showLoading = true;
		this.tracker.trackEvent('Announcement', 'Activation', announcement.name);
		let calendar = this.createCalendar(begin,  end, deliveryTypes);

		try {
			await this.announcements.one(this.appModel.currentAnnouncement.id.toString()).post('calendars', calendar, {'announcementVersion': this.appModel.currentAnnouncement.version});
			this.appModel.currentAnnouncement = null;
			this.appModel.deliveryPending = true;
			this.$log.info('Announcement aktiviert');
			if (end) {
				await this.createFollowUpAnnouncement(begin, end, deliveryTypes);
			}
		} catch (res) {
			this.$log.error('Error Activating Announcement');
			throw res;
		}
	}

	private async createFollowUpAnnouncement(begin, end, deliveryTypes): Promise<void> {
		this.$rs.showLoading = true;
		var followUpAnnouncement: ILastDelivery = this.findFollowUpAnnouncement(begin, deliveryTypes);
		this.appModel.lastDelivery = null; // switching on loading
		if (!followUpAnnouncement) {
			this.$rs.showLoading = false;
			this.$rs.errormessage = "WARNING_NO_FOLLOW_UP_FOUND";
			return;
		}
		this.tracker.trackEvent('Announcement', 'followUpActivation', followUpAnnouncement.name);
		try {
			await this.announcements.one(followUpAnnouncement.id.toString()).post('calendars', this.createCalendar(end, null, deliveryTypes), {
				'announcementVersion': followUpAnnouncement.version
			});
			this.$log.info('Follow Up Annoncement created');
		} catch (res) {
			this.$log.error('Error creating Followup Announcement');
			throw res;
		}
	}

	public findFollowUpAnnouncement(begin, deliveryTypes) {
		if (!this.appModel || !this.appModel.lastDelivery) {
			return null;
		}
		var followUpAnnouncement: ILastDelivery = null;
		this.checkDeliveryTypesValidForFollowUp(this.appModel.lastDelivery.calendar?.deliveryTypes, deliveryTypes) ? followUpAnnouncement = this.appModel.lastDelivery : null;
		var maxBegin: number = 0;
		_.each(this.appModel.announcements, (announcement: ILastDelivery) => {
			if (announcement.calendar.begin > maxBegin && announcement.calendar.begin < begin.getTime() && this.checkDeliveryTypesValidForFollowUp(announcement.calendar.deliveryTypes, deliveryTypes)) {
				followUpAnnouncement = announcement;
				maxBegin = announcement.calendar.begin;
			}
		});
		return followUpAnnouncement;
	}

	private checkDeliveryTypesValidForFollowUp(available: IDeliveryType[], deliveryTypes): boolean {
		var requestedIds = [];
		var availableIds = [];
		_.each(deliveryTypes, (tdt: ITemplateDeliveryTypes) => {
			if (tdt.selected) {
				requestedIds.push(tdt.deliveryType.id)
			}
		});
		_.each(available, (dt: IDeliveryType) => {
			availableIds.push(dt.id);
		});

		return _.intersection(requestedIds, availableIds).length === requestedIds.length;

	}

	private createCalendar(begin: Date, end: Date, deliveryTypes) {
		var cal: ICalendar = new Calendar;
		cal.version = 0;
		if (!begin) {
			cal.begin = 0;
		} else {
			cal.begin = begin.getTime();
		}

		if(end) {
			cal.end = end.getTime();
		}

		cal.deliveryTypes = [];

		for (var i = 0; i < deliveryTypes.length; i++) {
			var tdt: ITemplateDeliveryTypes = deliveryTypes[i];
			if (tdt.selected) {
				cal.deliveryTypes.push(tdt.deliveryType);
			}
		}
		return cal;
	}


}