import {IAppModel, IProperties, IRootScope, ITemplate} from '../hpc';
import {ILocationService} from 'angular';
import * as _ from "underscore";
import {IResponse} from "restangular";


export class TemplateService {

    public static $inject = ['$rootScope','$localStorage','$sessionStorage', 'properties', '$location', 'Restangular'];

    constructor(
        private $rs: IRootScope,
        private $localStorage: any,
        private appModel: IAppModel,
        private properties: IProperties,
        private $location: ILocationService,
        private Rest: any
    ) {
        this.getTemplates();
    }

    private getTemplates() {
        this.Rest.all('templates').getList()
        .then((data: ITemplate[]) => {
            console.log("success get templates: " + data.length);
            this.appModel.templates = data;
            this.appModel.groupedTemplates = _.groupBy(this.appModel.templates, 'templateTypeId');
        }, (data: IResponse) => {
            // TODO Error Handling
            console.log('Rest error ' + data);
        })
    }

    public refreshTemplates() {
        this.getTemplates();
    }


}