/**
 * Created with IntelliJ IDEA.
 * User: mjaeger
 * Date: 10.12.13
 * Time: 18:18
 * To change this template use File | Settings | File Templates.
 */


import {IAppModel} from "../hpc";
import {ILocationService} from "angular";


export let DetailCtrl = [
    '$scope',
    '$sessionStorage',
    '$location',
    '$log',
    function ($s,
              appModel: IAppModel,
              $location: ILocationService,
              $log: angular.ILogService) {
        if (!appModel.currentTemplate) {
            $location.path('/myGreetings')
        }

        // $s.noSchedular = $location.search().noScheduler;
        // $s.type = $location.search().type;

    }];


