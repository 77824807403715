/**
 * Created with IntelliJ IDEA.
 * User: mjaeger
 * Date: 10.12.13
 * Time: 18:10
 * To change this template use File | Settings | File Templates.
 */


'use strict';


import {InfoService} from "../services/InfoService";
import {IAppModel, IBaseScope, IRootScope} from "../hpc";
import {TemplateService} from "../services/TemplateService";

interface IMainScope extends IBaseScope {
    appModel: IAppModel;
    calendarsync: boolean;
    onTemplateSelected: () => void;
    onCreateAnnouncement: (any) => void;
    onEditAnnouncement: (any) => void;
    $location: ng.ILocationService;
    showCurrentAnnouncement: () => void;
    showTemplateList: (any) => void;
    showAnnouncementList: () => void;
    isAutoModeAllowed: () => boolean;
    isAutoModeConfigured: () => boolean;
    isAutoModeEnabled: () => boolean;
    toggleAutoMode: (any) => void;
    syncCalendar: () => void;
    showMoreButton: () => boolean;
}


export let MainCtrl = [
    '$scope',
    '$rootScope',
    'properties',
    '$sessionStorage',
    'Restangular',
    '$location',
    '$timeout',
    '$log',
    'infoService',
    'templateService',
    function ($s: IMainScope,
              $rs: IRootScope,
              properties,
              appModel: IAppModel,
              Rest,
              $location: ng.ILocationService,
              $timeout,
              $log: angular.ILogService,
              infoService:InfoService,
              templateService:TemplateService //required to init templates
    ) {
    
        $s.appModel = appModel;
        $s.$location = $location;
        window.scrollTo(0, 0);


        $s.showCurrentAnnouncement = () => {
            //return false;
            return $rs.viewMode=='desktop' || !$location.search().selectTemplate
            //return (!$s.appModel.account.accountProperties.oooAnnouncementId || !$s.appModel.account.accountProperties.oooAnnouncementId);
        }

        $s.showTemplateList = () => {
            let result = !$s.isAutoModeEnabled() || ($s.isAutoModeConfigured() && appModel.showTemplateList);
            return result;
            //return $location.search().selectTemplate || !appModel.account.accountProperties.calendarsync || (appModel.account.accountProperties.calendarsync && $s.isAutoModeConfigured());
        }

        $s.isAutoModeEnabled = () => {
            return appModel.account.accountProperties.calendarsync;
        }

        $s.isAutoModeConfigured = () => {
           return appModel.storedAnnouncements?.defaultAnnouncement && appModel.storedAnnouncements?.oooAnnouncement;
        }

        $s.showAnnouncementList = () => {
            let result = $s.isAutoModeAllowed() && appModel.account.accountProperties?.calendarsync && !$location.search().selectTemplate;
            return result;
        }

        $s.isAutoModeAllowed = () => {
            return appModel.mandator.mandatorAttributes.enableAutoMode || appModel.accountPermissions.indexOf(220) > -1;
        }

        $s.showMoreButton = () => {
            return $s.isAutoModeAllowed() && $s.isAutoModeConfigured() && $s.isAutoModeEnabled();
        }

        $s.toggleAutoMode = ($event) => {
            console.log('toggle automode');
            $event.preventDefault();
            $rs.showLoading = true;
            //retrieve value of target and authorize if true. disable calendarsync if false
            let target = $event.target;
            $rs.$broadcast('toggleAutoMode', target.checked);
        }

        infoService.displayInitMessages();

    }];