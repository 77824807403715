import {IAppModel, IRootScope} from "../hpc";
import {ILogService} from "angular";

export enum playStatus {
	STOPPED,
	LOADING,
	PLAYING
}

export class PrelistenService {

	private audio = new Audio();
	public playStatus = playStatus.STOPPED;
	public currentAnnouncement;

	public static $inject = ['$rootScope','$sessionStorage', 'properties', '$log'];


	constructor(
		private $rs: IRootScope,
		private appModel: IAppModel,
		private properties:any,
		private $log: ILogService
	) {
		this.audio.volume = 0.8;
		this.audio.addEventListener('ended', () => {
			this.playStatus = playStatus.STOPPED;
			$rs.$apply();
		});
		this.audio.addEventListener('playing', () => {
			this.playStatus = playStatus.PLAYING;
			$rs.$apply();
			console.log('playing');
		});
		this.audio.addEventListener('loadstart', () => {
			this.playStatus = playStatus.LOADING;
			$rs.$apply();
			console.log('loadstart');
		});
		this.audio.addEventListener('canplay', (e) => {
			console.log('canplay');
		});
		this.audio.addEventListener('error', (e) => {
			console.log('error playing audio');
			this.playStatus = playStatus.STOPPED;
			$rs.$apply();
			this.$rs.errormessage = 'error playing audio';
		});

		// Listen for route change events
		$rs.$on('$locationChangeStart', () => {
			this.stopPlayback();
		});
	};

	public stopPlayback() {
		if (this.playStatus !== playStatus.STOPPED) {
			this.audio.pause();
			this.currentAnnouncement = null;
			this.playStatus = playStatus.STOPPED;
		}
	}


	public togglePrelisten(announcement) {
		if(this.playStatus != playStatus.STOPPED && announcement == this.currentAnnouncement) {
			this.audio.pause();
			this.currentAnnouncement = null;
			this.playStatus = playStatus.STOPPED;
			return;
		}
		this.currentAnnouncement = announcement;
		let fileUrl = this.properties.restBaseUrl + "announcements/" + announcement.id.toString() + "/stream";
		fileUrl += "?access_token=" + this.appModel.userToken;

		// if (announcement.endDate != null) {
		// 	const endDateParam = "&endDate=" + new Date(announcement.endDate).getTime();
		// 	fileUrl += endDateParam;
		// }

		if (announcement.calendar != null) {
			const calendarIdParam = "&calendarId=" + announcement.calendar.id;
			fileUrl += calendarIdParam;
		}

		this.audio.src = fileUrl;
		this.audio.play();
	}

	public download(announcement, mediaType, filename){
		let fileUrl = this.properties.restBaseUrl + "announcements/" + announcement.id.toString() + "/stream";

		var a = document.createElement('a');
		a.href = fileUrl + "?access_token=" + this.appModel.userToken;
		if(mediaType){
			a.href = a.href + '&mediaType=' + mediaType.type + "&fileName=" + filename + "." + mediaType.extension;
		}
		a.target = '_blank';
		a.click();
	}



	public audioSupported() {
		return !!(this.audio.canPlayType && this.audio.canPlayType('audio/mpeg;').replace(/no/, ''));
	}


}