import {app} from "../../app";
import {AbstractComponent} from "../AbstractComponent/AbstractComponent";
import {ILogService} from "angular";
import {IAppModel, IRootScope} from "../../hpc";
import {DeliveryService} from "../../services/DeliveryService";
import "angular-ui-bootstrap";

export class Scheduler extends AbstractComponent {

	public static $inject = ['$log', '$rootScope', '$scope', '$sessionStorage', '$timeout' ,'deliveryService'];

	constructor(
		private $log: ILogService,
		private $rs: IRootScope,
		private $s,
		public appModel: IAppModel,
		public $timeout: ng.ITimeoutService,
		public deliveryService: DeliveryService
	) {
		super();
	}

	public begin: Date = new Date();
	public end: Date = new Date(this.begin.getTime() + 24 * 60 * 60 * 1000);
	public isActivateNow: boolean;
	public noEnd: boolean = true;
	public fullDaySchedule: boolean = true;
	public showBegin: boolean = false;
	public showEnd: boolean = false;
	public deliveryTypes;

	$onInit(): void {

		this.$s.$watch('$ctrl.begin', (newV, oldV) => {
			if (newV != oldV) {
				this.isActivateNow = false;
				this.showBegin = false;
			}
		});

		this.$s.$watch('$ctrl.end', (newV, oldV) => {
			if (newV !== oldV) {
				this.noEnd = false;
				if (this.fullDaySchedule && this.showEnd) {
					this.end = new Date(this.end.setHours(23, 59, 59, 999));
				}
				this.showEnd = false;
			}
		});
	}

	updateEndDate(which: String) {
		this.noEnd = false;
		switch (which) {
			case '30m':
				this.end = new Date(this.begin.getTime() + 30 * 60 * 1000);
				break;
			case '1h':
				this.end = new Date(this.begin.getTime() + 60 * 60 * 1000);
				break;
			case '2h':
				this.end = new Date(this.begin.getTime() + 2 * 60 * 60 * 1000);
				break;
			case 'eob':
				this.end = new Date(this.begin.setHours(20, 0, 0, 0));
				break;
			case '1t':
				this.end = new Date(this.begin.getTime() + 24 * 60 * 60 * 1000);
				break;
			case '2t':
				this.end = new Date(this.begin.getTime() + 2 * 24 * 60 * 60 * 1000);
				break;
			case 'kw':
				this.end = new Date(this.begin.getTime() + 7 * 24 * 60 * 60 * 1000);
				break;
			case '2kw':
				this.end = new Date(this.begin.getTime() + 14 * 24 * 60 * 60 * 1000);
				break;
		}
	}

	hasFollowUpAnnouncement() {
		return !!this.deliveryService.findFollowUpAnnouncement(this.begin, this.deliveryTypes);
	}

	startDateBeforeRender($dates) {
		const todaySinceMidnight = new Date();
		todaySinceMidnight.setUTCHours(0,0,0,0);
		$dates.filter(function (date) {
			return date.utcDateValue < todaySinceMidnight.getTime();
		}).forEach(function (date) {
			date.selectable = false;
		});
	};
}

app.component('scheduler', {
	bindings: {
		isActivateNow: '=',
		begin: '=',
		end: '=',
		noEnd: '=',
		showEnd: '=',
	},
	templateUrl: 'ts/components/scheduler/scheduler.html',
	controller: Scheduler
});
