import {app} from "../../app";
import {AbstractComponent} from "../AbstractComponent/AbstractComponent";
import {ILocationService, ILogService} from "angular";
import {IAppModel, IRootScope, ITemplate} from "../../hpc";
import {TemplateService} from "../../services/TemplateService";

export class TemplateList extends AbstractComponent {

    public static $inject = ['$log', '$rootScope', '$sessionStorage', 'Restangular', '$location', 'templateService'];

    constructor(private $log: ILogService,
                private $rs: IRootScope,
                private appModel: IAppModel,
                private Rest,
                private $location: ILocationService,
                private templateService: TemplateService) {
        super();
    }

    typeFilter: string;

    $onInit(): void {
       this.typeFilter = this.filter;
        switch (this.typeFilter) {
            case 'defaultAnnouncement':
                this.typeId = 1;
                break;
            case 'oooAnnouncement':
                this.typeId = 20;
                break;
            default:
                this.typeId = null;
        }
    }

    public typeId: number;

    public getGroupedAndFilteredTemplates() {
        if (this.typeId !== undefined && this.typeId !== null) {
            return { [this.typeId]: this.appModel.groupedTemplates[this.typeId] || [] };
        }
        return this.appModel.groupedTemplates;
    }



    public selectTemplate(template: ITemplate) {
        this.appModel.currentTemplate = template;
        this.Rest.one('templates', template.id).all('announcement').post(null)
        .then(
            (data) => {
                this.appModel.currentAnnouncement = data;
                this.$rs.slideLeft();
                this.$location.path("/greetingDetail").search({
                    noScheduler: this.isEdit,
                    type: this.typeFilter});
            });
    }
}

app.component('templateList', {
    bindings: {
        onTemplateSelected: '&',
        filter: '<',
        isEdit: '<'
    },
    templateUrl: 'ts/components/templateList/templateList.html',
    controller: TemplateList
})