/**
 * Created with IntelliJ IDEA.
 * User: mjaeger
 * Date: 10.12.13
 * Time: 18:10
 * To change this template use File | Settings | File Templates.
 */
import {IAppModel, IBaseScope, IMandatorLanguage, IRootScope} from "../hpc";
import * as _ from "underscore";
import {ILogService, IWindowService} from "angular";
import * as restangular from "restangular";
import {TrackerService} from "../services/TrackerService";
import {LanguageSwitchService} from '../services/LanguageSwitchService';
import {InfoService} from "../services/InfoService";


interface INavScope extends IBaseScope {
    isCollapsed: boolean;
    $back: any;
    showBack: any;
    $logout: any;
    isLoggedIn: () => boolean;
    toogleMenu: (any) => void;
    showNav: () => boolean;
    $settings: () => void;
    goto: (url: String) => void;
    goToAdmin: () => void;
    getFeedbackPath: () => string;
    appModel: IAppModel;
    languageSwitch: LanguageSwitchService;
    selectedLanguage: IMandatorLanguage;
    isAdmin: () => boolean;
    showAdminButton(): ()=>boolean;
    confirmErrorMessage: (id: string) => void;
    isConfirmed: (id: string) => boolean;

}

export let NavCtrl = [
    '$scope',
    '$rootScope',
    'properties',
    '$log',
    '$location',
    '$route',
    '$sessionStorage',
    '$localStorage',
    '$cookies',
    'Restangular',
    '$window',
    'tracker',
    'languageSwitch',
    '$timeout',
    'infoService',
    function ($s: INavScope,
              $rs: IRootScope,
              properties,
              $log: ILogService,
              $location,
              $route: angular.route.IRouteService,
              appModel: IAppModel,
              $localStorage,
              $cookies: ng.cookies.ICookiesService,
              Rest: restangular.IService,
              $window: IWindowService,
              tracker: TrackerService,
              languageSwitch: LanguageSwitchService,
              $timeout,
              infoService: InfoService) {

        $s.appModel = appModel;
        $s.languageSwitch = languageSwitch;
        $s.selectedLanguage = languageSwitch.currentMandatorLanguage;

        Rest.setDefaultHeaders({"Authorization": "Bearer " + appModel.userToken});
        $s.isCollapsed = true;
        $rs.layouts = appModel.layouts;

        $rs.slideLeft = function () {
            $log.info('setting slide left')
            $rs.slide = 'slide-left';
            $timeout(function () {
                $rs.slide = 'slide-right'
            }, 700);
        }

        /**
         * Window Back
         */
        $rs.$back = function () {
            $s.isCollapsed = true;
            window.history.back();
        };


        /**
         * Things need to be done on Page Change.
         * - Hide error for slide animation performance
         */
        $rs.$on('$locationChangeStart', function (event: angular.IAngularEvent, newUrl, oldUrl) {
            $log.info('Locationchange start: ' + newUrl + " old:" + oldUrl);
            if ($rs.hideGlobalError) {
                return;
            }
            $rs.errormessage = null;
            $rs.showLoading = false;
            $rs.hideGlobalError = true;
            $timeout(function () {
                $rs.hideGlobalError = false
            }, 700)
        })


        /**
         * show the back button?
         *
         * @returns {boolean}
         */
        $rs.showBack = function (path = $location.path()) {
            return (_.indexOf(['/login', '/main', '/register'], $location.path()) == -1);
        };

        /**
         * perform logout
         */
        $s.$logout = function () {
            $location.path('/logout');
        };

        $s.goto = function (url) {
            $location.path(url);
        }

        /**
         * check if userToken is available
         *
         * @returns {boolean}
         */
        $rs.isLoggedIn = function () {
            return !!appModel.userToken && !!appModel.account;
        }

        $s.toogleMenu = function ($event) {
            $s.isCollapsed = !$s.isCollapsed;
            $event ? $event.stopPropagation() : null;
        };

        $s.showNav = function () {
            //return true;
            //return (_.indexOf(['/login', '/forgotPassword'], $location.path()) == -1);
            return $rs.isLoggedIn();
        };

        $s.getFeedbackPath = function () {
            if (appModel.demoMode) {
                return "iFrame?url=https:%2F%2Fwww.hiprocall.de%2Fkontakt";
            } else {
                return "feedback";
            }
        }

        $s.$settings = function () {
            $location.path('/settings');
        };

        $s.showAdminButton = function () {
            return $s.appModel.isAdmin && ($s.appModel.properties.showAdminButton || $s.appModel.mandator?.mandatorAttributes?.showAdminButton);
        }

        $s.goToAdmin = function () {
            let origin = window.location.origin.endsWith('/') ? window.location.origin.slice(0, -1) : window.location.origin;
            let baseUrl = properties.adminTokenAuthBaseUrl.startsWith('/') ? properties.adminTokenAuthBaseUrl.slice(1) : properties.adminTokenAuthBaseUrl;

            let tokenAuthUrl = `${origin}/${baseUrl}?t=${appModel.userToken}`;
            window.location.href = tokenAuthUrl;
        }

        $s.confirmErrorMessage = function (id) {
           infoService.confirmErrorMessage(id);
        }

        //redirect if already logged in
        if (!$rs.isLoggedIn() && $location.path() != "/register") {
            $location.path('/login')
        }

    }];
